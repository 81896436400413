import {Box, useTheme} from '@mui/joy';

export function SideBySideLayout({left, right}) {
  return (
    <>
      <Box
        sx={{
          width: {xs: '100%', md: '50vw'},
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: '#fff',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          {left}
        </Box>
      </Box>
      <Box
        sx={theme => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: {xs: 0, md: '50vw'},
          zIndex: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          background: 'linear-gradient(180deg, #0b1320 0%, #1f1f4d 100%)',
          p: 5,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          {right}
        </Box>
      </Box>
    </>
  );
}