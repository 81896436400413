import { Box, Typography } from '@mui/joy';

export function RoundNumber({ number, size, sx, done }) {
  const pixelSize = size === 'sm' ? '26px' : '30px';
  const level = size === 'sm' ? 'body-sm' : 'h4'
  return (
    <Box sx={{
      backgroundColor: done ? 'var(--joy-palette-success-500)' : 'var(--joy-palette-neutral-400)',
      borderRadius: '50%',
      height: pixelSize,
      width: pixelSize,
      flex: '0 0 ' + pixelSize,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx
    }}>
      <Typography level={level} sx={{ color: 'white', fontWeight: '600' }}>{number}</Typography>
    </Box>
  );
}
