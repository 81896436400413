import {FormLabel, FormControl, FormHelperText, Input, Select, MenuItem, Option, Stack, Box, Button, Dropdown, MenuButton, Menu, List, ListItem, ListDivider, Typography} from '@mui/joy';
import { FormButtons } from '@/components/FormButtons';
import { useParams, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useFormContext, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { callSchema } from '../schema';
import {useGetCallTemplatesQuery, useGetSharedCallTemplatesQuery} from '../../callTemplates/api';
import {useGetCompaniesQuery} from "@/features/companies/api.js";
import {FormSelectEntity} from "@/components/forms/FormSelectEntity.jsx";
import {SelectSharedPlaybook} from "@/components/features/SelectSharedPlaybook.jsx";
import {useState} from "react";
import {useGetContactsQuery} from "@/features/contacts/api.js";

export function CallForm({ data, save, saveResult }) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(callSchema),
  });

  const errors = form.formState.errors;

  async function onSubmit(data) {
    await save(data);
    navigate(`/p/${projectId}/calls`);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            pt: 2,
            pb: 3,
          }}
        >
          <Stack spacing={3}>
            <FormControl error={!!errors.meeting_url}>
              <FormLabel>Google Meet URL</FormLabel>
              <Input {...form.register("meeting_url")} placeholder="e.g. https://meet.google.com/..." />
              {errors.meeting_url && <FormHelperText>{errors.meeting_url.message}</FormHelperText>}
            </FormControl>

            <FormControl error={!!errors.template_id}>
              <FormLabel>Call type</FormLabel>
              <CallTemplateSelect />
              {errors.template_id && <FormHelperText>{errors.template_id.message}</FormHelperText>}
            </FormControl>

            <FormControl error={!!errors.company_id}>
              <FormLabel>Company</FormLabel>
              <CompanySelect />
              {errors.company_id && <FormHelperText>{errors.company_id.message}</FormHelperText>}
            </FormControl>

            <FormControl error={!!errors.contact_id}>
              <FormLabel>Contact</FormLabel>
              <ContactSelect />
              {errors.contact_id && <FormHelperText>{errors.contact_id.message}</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>
        <FormButtons isSaving={saveResult.isLoading} />
      </form >
    </FormProvider>
  );
}

export function CallTemplateSelect() {
  const name = "template_id";
  const placeholder = "Select call playbook";

  const {control, setValue} = useFormContext();

  const {projectId} = useParams();
  const {data, isLoading } = useGetCallTemplatesQuery({projectId});

  const items = data?.data ?? [];

  const [open, setOpen] = useState(false);

  function onClone({ id }) {
    setValue(name, id);
    setOpen(false);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {ref, value, onChange, onBlur}, fieldState: {error}}) => {
        return (
          isLoading ? 'Loading...' : (
            <Select
              listboxOpen={open}
              onListboxOpenChange={(isOpen) => {
                setOpen(isOpen);
              }}
              ref={ref}
              value={value}
              onChange={(_, newValue) => onChange(newValue) }
              error={error == null ? 'false' : 'true'}
              placeholder={placeholder}
            >
              <List>
                <ListItem>
                  <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}>Your playbooks</Typography>
                </ListItem>
                {items.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </List>
              <ListDivider role="none" />
              <List>
                <ListItem sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ width: "100%", maxWidth: "678px" }}>
                  <Typography level="body-xs" sx={{ textTransform: 'uppercase', my: 0.5 }}>Shared playbooks</Typography>
                  <SelectSharedPlaybook hideHeader={true} onClone={onClone} />
                  </Box>
                </ListItem>
              </List>
            </Select>
          ));
      }}
    />
  );
}

export function CompanySelect() {
  return <FormSelectEntity name="company_id" nullable={true} placeholder={"Select company"} useQuery={useGetCompaniesQuery} />;
}

export function ContactSelect() {
  function displayFn(item) {
    return `${item.first_name} ${item.last_name}`;
  }

  return <FormSelectEntity name="contact_id" displayFn={displayFn} nullable={true} placeholder={"Select contact"} useQuery={useGetContactsQuery} />;
}