import  { ContactsPage } from './pages/ContactsPage'
import  { CreateContactPage } from './pages/CreateContactPage'
import  { UpdateContactPage } from './pages/UpdateContactPage'

export const contactsRoutes = {
  path: 'contacts',
  children: [{
    path: '',
    element: <ContactsPage />,
    children: [{
      path: 'create',
      element: <CreateContactPage />,
    }, {
      path: ':id',
      element: <UpdateContactPage />,
    }],
  }],
};
