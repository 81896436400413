import {Button, Divider, DialogTitle, DialogContent, DialogActions, Modal, ModalDialog, Alert, Box} from '@mui/joy';
import {useState} from "react";

export function AlertDialogModal({ open, setOpen, props }) {
  const [confirming, setConfirming] = useState(false);
  const [error, setError] = useState('');

  async function confirm(e) {
    e.stopPropagation();
    setConfirming(true);
    try {
      await props.onConfirm();
      setOpen(false);
    } catch (e) {
      setError(e.message)
    }
    setConfirming(false);
  }

  function cancel(e) {    
    e.stopPropagation();
    setOpen(false);    
  }

  return (          
    <Modal open={open} onClose={cancel}>
      <ModalDialog variant="outlined" role="alertdialog" onClick={(e) => e.stopPropagation()}>
        <DialogTitle>
          { props.title }
        </DialogTitle>
        <Divider />
        <DialogContent>
          {error && <Alert color="danger" sx={{ mt: 1, mb: 1 }}>{error}</Alert>}
          <Box sx={{ mt: 1 }}>{ props.message }</Box>
        </DialogContent>
        <DialogActions>
          <Button variant="solid" loading={confirming} color={props.confirmActionColor} onClick={confirm}>
            { props.confirmActionText }
          </Button>
          <Button variant="plain" color="neutral" onClick={cancel}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>    
  );
}