import {FormProvider, useForm} from 'react-hook-form';
import {
  Button,
  Typography,
  Stack,
  Divider,
  Alert,
} from '@mui/joy';
import {auth} from '../firebase';
import {AuthLayout} from "@/components/layouts/AuthLayout.jsx";
import {yupResolver} from "@hookform/resolvers/yup";
import {signUpSchema, newSignUp} from "@/features/auth/schema.js";
import {fromAuthErrorCode} from "@/features/auth/errors.js";
import {Navigate} from "react-router-dom";
import {useCreateUserWithEmailAndPassword} from "react-firebase-hooks/auth";
import {GoogleButton} from "@/features/auth/components/GoogleButton.jsx";
import {FormInput} from "@/components/forms/FormInput.jsx";
import {Link} from "@/components/Link.jsx";
import {FormCheckbox} from "@/components/forms/FormCheckbox.jsx";

export function SignUpPage() {
  const form = useForm({
    defaultValues: newSignUp(),
    resolver: yupResolver(signUpSchema),
  });

  const [
    createUserWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useCreateUserWithEmailAndPassword(auth);

  function onSubmit(data) {
    createUserWithEmailAndPassword(data.email, data.password)
  }

  if (user != null) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <AuthLayout>
      <Stack gap={4}>
        {error && <Alert color="danger">{fromAuthErrorCode(error.code)}</Alert>}
        <Stack gap={1}>
          <Typography component="h1" level="h2">Sign up</Typography>
          <Typography level="body-sm">
            Already have account?{' '}<Link to="/sign-in" level="title-sm">Sign in!</Link>
          </Typography>
        </Stack>
        <GoogleButton />
        <Divider>or</Divider>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <FormInput name="email" label="Email" size="lg" />
              <FormInput name="password" label="Password" type="password" size="lg" />
              <FormCheckbox name="agreement" label={<div>
                I agree to the <Link to="https://getraise.ai/terms-of-use" target="_blank" sx={{zIndex: 2}}>terms of use</Link>
                {' '}and <Link to="https://getraise.ai/privacy-policy" target="_blank"  sx={{zIndex: 2}}>privacy policy</Link>
              </div>} />
              <Button type="submit" loading={loading} fullWidth sx={{mt: 2.5}} size="lg">Sign up</Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </AuthLayout>
  );
}
