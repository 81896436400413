import {FormProvider, useForm} from 'react-hook-form';
import {Button, Typography, Stack, Alert} from '@mui/joy';
import {auth} from '../firebase';
import {useSendPasswordResetEmail} from 'react-firebase-hooks/auth';
import {AuthLayout} from "@/components/layouts/AuthLayout.jsx";
import {yupResolver} from "@hookform/resolvers/yup";
import {forgottenPasswordSchema, newForgottenPassword} from "@/features/auth/schema.js";
import {fromAuthErrorCode} from "@/features/auth/errors.js";
import {FormInput} from "@/components/forms/FormInput.jsx";
import {Link} from "@/components/Link.jsx";
import {useState} from "react";

export function ForgottenPasswordPage() {
  const [
    sendPasswordResetEmail,
    loading,
    error,
  ] = useSendPasswordResetEmail(auth);

  const [success, setSuccess] = useState(false);

  async function onSubmit(data) {
    const success = await sendPasswordResetEmail(data.email)
    setSuccess(success);
  }

  return (
    <AuthLayout>
      <Stack gap={4}>
        {error && <Alert color="danger">{fromAuthErrorCode(error.code)}</Alert>}
        {success ? <SuccessMessage/> : <ResetForm onSubmit={onSubmit} loading={loading}/>}
      </Stack>
    </AuthLayout>
  );
}

function SuccessMessage() {
  return (
    <Stack gap={3}>
      <Typography component="h1" level="h2">Password reset email sent</Typography>
      <Typography level="body-sm">
        Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam
        folder.
      </Typography>
      <Link to="/sign-in" level="title-sm">Back to Sign in</Link>
    </Stack>
  );
}

function ResetForm({onSubmit, loading}) {
  const form = useForm({
    defaultValues: newForgottenPassword(),
    resolver: yupResolver(forgottenPasswordSchema),
  });

  return (<>
      <Stack gap={1}>
        <Typography component="h1" level="h2">Password reset</Typography>
        <Typography level="body-sm">
          Enter your email to reset password{' '}
        </Typography>
      </Stack>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <FormInput name="email" label="Email" size="lg" />
            <Link to="/sign-in" level="title-sm">Back to Sign in</Link>
            <Button type="submit" loading={loading} fullWidth sx={{mt: 2.5}} size="lg">Reset password</Button>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
}
