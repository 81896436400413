export class AgendaFulfillment {
  constructor(data, fulfilled) {
    this.data = data;
    this._explicitlyFulfilledPoints = fulfilled.map(item => item.point);
    this._initPointsFulfillment();
    this._initNextActionItems();
  }

  isPointFulfilled(index) {
    return this._pointsFulfillment[index];
  }

  isSubPointFulfilled(pointIndex, subPointIndex) {
    return this._explicitlyFulfilledPoints.includes(pointIndex + 1 + '.' + (subPointIndex + 1))
  }

  getNextUnfulfilledPoint() {
    return this._nextUnfulfilledPoint;
  }

  getMissedPoints() {
    return this._missedPoints;
  }

  _initPointsFulfillment() {
    this._pointsFulfillment = [];
    for (let i = 0; i < this.data.points.length; i++) {
      const done = this._shouldBePointFulfilled(this.data.points[i], i);
      this._pointsFulfillment.push(done);
    }
  }

  _initNextActionItems() {
    this._nextUnfulfilledPoint = null;
    this._missedPoints = [];

    let lastFulfilledPointIndex = this._getLastFulfilledPointIndex();
    for (let i = lastFulfilledPointIndex + 1; i < this._pointsFulfillment.length; i++) {
      if (!this._pointsFulfillment[i]) {
        this._nextUnfulfilledPoint = i;
        break;
      }
    }

    for (let i = 0; i < lastFulfilledPointIndex; i++) {
      if (!this._pointsFulfillment[i]) {
        this._missedPoints.push(i);
      }
    }
  }

  _getLastFulfilledPointIndex() {
    let lastFulfilledPointIndex = -1;
    for (let i = 0; i < this._pointsFulfillment.length; i++) {
      if (this._pointsFulfillment[i]) {
        lastFulfilledPointIndex = i;
      }
    }
    return lastFulfilledPointIndex;
  }

  _shouldBePointFulfilled(point, index) {
    return this._explicitlyFulfilledPoints.includes(index + 1) || (
      point.sub_points.length > 0 && point.sub_points.every(
        (subPoint, subIndex) => this.isSubPointFulfilled(index, subIndex)
      )
    );
  }
}
