import { useParams, useNavigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Button, Stack, Box, Card, Typography } from "@mui/joy";
import { ProjectLayout } from '@/components/layouts/ProjectLayout.jsx';
import { PageHeader } from '@/components/PageHeader';
import { Agenda } from '@/components/features/Agenda/Agenda.jsx';
import { Objections } from '@/components/features/Objections';
import { useGetCallTemplateQuery } from '@/features/callTemplates/api';
import { useCreateCallMutation } from "@/features/calls/api";
import { ListDebugCalls } from "../components/ListDebugCalls";
import { reset } from "../state";

export function DebugCallTemplatePage() {
  const { projectId, id } = useParams();  
  const { isFetching, data } = useGetCallTemplateQuery({ projectId, id });    
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (data) {
      dispatch(reset());
    } 
  }, [data]);

  return (
    <ProjectLayout>
      <PageHeader title="Debug Playbook" />
      {isFetching ? 'Loading...' : <DebugCallTemplate data={data.data} />}
    </ProjectLayout>    
  );
}

function DebugCallTemplate({ data }) {
  const { projectId, id: templateId } = useParams();  
  const [saveCall, saveResult] = useCreateCallMutation();
  const navigate = useNavigate();
  const activeCall = useSelector(state => state.activeCall);

  async function simulateNewCall() {
    const call = { 
      template_id: templateId, 
      meeting_url: "https://debug.getraise.ai/",
      status: "active" 
    };
    const { data: result } = await saveCall({ projectId, data: call });
    navigate(`./${result.data.id}`);    
  }

  return (    
    <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
      <Box sx={{ gridColumn: 'span 6' }}>        
        <Stack gap={2}>
          <Card sx={{ p: 3, py: 2 }}>
            <Typography level="h4">Objections</Typography>
            <Box>
              <Objections data={activeCall.objections} />
            </Box>
          </Card>
          <Card sx={{ p: 3, pt: 2 }}>
            <Typography level="h4">Agenda</Typography>
            <Agenda data={data.agenda} fulfilled={activeCall.fulfilled_agenda_items} />
          </Card>      
        </Stack>        
      </Box>
      <Box sx={{ gridColumn: 'span 6' }}>
        <Stack gap={2}>
          <Outlet />
          <Card>
            <Button onClick={simulateNewCall}>Simulate new call</Button>            
            <ListDebugCalls templateId={templateId} />
          </Card>
        </Stack>   
      </Box>
    </Box>
  );
}
