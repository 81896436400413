import {Box, Typography} from "@mui/joy";

export function OnboardingTip({title, subtitle}) {
  return (<Box
      component="main"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        width: 660,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        textAlign: 'left',
      }}
    >
      <Typography level="h1" sx={{ color: '#fff', lineHeight: 1.1 }}>
        {title}
      </Typography>

      <Typography level="title-lg" sx={{ color: '#fff', lineHeight: 1.41, fontWeight: 400, fontSize: "24px" }}>
        {subtitle}
      </Typography>
    </Box>
  );
}