import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { NavLink, Link, useParams } from 'react-router-dom';
import { Box, Divider, List, ListItem, ListItemContent, Typography, Sheet } from '@mui/joy';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { ActiveUser } from './ActiveUser';
import { ProjectSwitcher } from './ProjectSwitcher';

const links = [
  { to: '/calls', label: 'Calls', icon: <CallRoundedIcon /> },
  { to: '/companies', label: 'Companies', icon: <BusinessRoundedIcon /> },
  { to: '/contacts', label: 'Contacts', icon: <PersonRoundedIcon /> },
  { to: '/playbooks', label: 'Playbooks', icon: <AssignmentRoundedIcon /> },
];

export function Sidebar() {
  const { projectId } = useParams();  

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: 'sticky',
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 1000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: '0 0 16px inset rgba(0, 0, 0, 0.04)',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-neutral-100)'
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <ProjectSwitcher />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 1.5,
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {links.map((link) => (
            <NavLink key={link.to} to={`/p/${projectId}${link.to}`} >
              {({ isActive }) => (
                <ListItem>
                  <ListItemButton selected={isActive} color={isActive ? 'primary' : 'neutral'}
                                  sx={{ "&:not(.Mui-selected, [aria-selected=\"true\"]):hover": { backgroundColor: 'var(--joy-palette-neutral-200)' }}}>
                    {link.icon}
                    <ListItemContent>
                      <Typography level="title-sm">{link.label}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              )}
            </NavLink>
          ))}          
        </List>
        {/*<List*/}
        {/*  size="sm"*/}
        {/*  sx={{*/}
        {/*    mt: 'auto',*/}
        {/*    flexGrow: 0,*/}
        {/*    '--ListItem-radius': (theme) => theme.vars.radius.sm,*/}
        {/*    '--List-gap': '8px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Link to="/admin/organizations">*/}
        {/*    <ListItem>*/}
        {/*      <ListItemButton>*/}
        {/*        <ViewListRoundedIcon />*/}
        {/*        Admin*/}
        {/*      </ListItemButton>*/}
        {/*    </ListItem>*/}
        {/*  </Link>*/}
        {/*</List>*/}
      </Box>
      <ActiveUser />
    </Sheet>
  );
}