import { Chip } from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

const statusMap = {
  pending: { text: 'Pending', variant: 'solid', color: 'neutral', icon: <ScheduleRoundedIcon /> },
  active: { text: 'In progress', variant: 'solid', color: 'warning', icon: <CallRoundedIcon /> },
  finished: { text: 'Finished', variant: 'solid', color: 'success', icon: <CheckRoundedIcon /> },
  error: { text: 'Error', variant: 'solid', color: 'danger', icon: <BlockIcon /> },
};

export function CallStatus({ size, status }) {
  const { color, variant, text, icon } = statusMap[status];
  return (
    <Chip variant={variant} size={size ?? 'sm'} color={color} startDecorator={icon}>
      {text}
    </Chip>
  );
}
