import {Navigate} from "react-router-dom";
import {FullScreenLoader} from "@/components/FullScreenLoader.jsx";
import {useGetProfileQuery} from "@/features/onboarding/api.js";
import {useGetProjectsQuery} from "@/features/projects/api.js";
import {ApiError} from "@/components/ApiError.jsx";

export function DefaultProjectPage() {
  const {data: profile, error: profileError, isLoading: isProfileLoading} = useGetProfileQuery();
  const {data: projects, error: projectsError, isLoading: areProjectsLoading} = useGetProjectsQuery();

  if (isProfileLoading || areProjectsLoading) return <FullScreenLoader/>;
  if ((profileError && profileError.status !== 404) || projectsError) return <ApiError error={profileError || projectsError} />;

  const path = (profile?.data) ? '/p/' + projects.data[0].id : '/onboarding';
  return <Navigate to={path} replace={true} />;
}
