import { Box, FormControl, FormHelperText, Input, Divider, Stack, Typography, Button } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import { useFormContext, useFieldArray } from "react-hook-form";
import { RoundNumber } from '@/components/RoundNumber';
import { RemoveFormElementButton } from '@/components/RemoveFormElementButton';
import { newPoint, MIN_POINTS, MAX_POINTS, MIN_SUB_POINTS, MAX_SUB_POINTS, MIN_EXAMPLES, MAX_EXAMPLES } from '../schema';

export function AgendaPointsForm() {
  const { fields: points, append, remove } = useFieldArray({ name: 'agenda.points' });

  return (
    <Stack gap={1}>
      {points.map((field, index) => <Box key={index}>
        <Divider sx={{ mx: -2, mb: 2 }} />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <RoundNumber number={index + 1} size="md" sx={{ mt: '2px' }} />
          <PointForm key={field.id} index={index} />
          {points.length > MIN_POINTS && <RemoveFormElementButton size="md" onClick={() => remove(index)} />}
        </Box>
      </Box>)}
      {points.length < MAX_POINTS && <>
        <Divider sx={{ mx: -2, mb: 1 }} />
        <Button startDecorator={<Add />} variant="outlined" onClick={() => append(newPoint())}>Add Point</Button>
      </>}
    </Stack>
  );
}

function PointForm({ index }) {
  const path = `agenda.points.${index}`;

  const form = useFormContext();
  const titleErrors = form.getFieldState(`${path}.title`).error;

  return (
    <Stack spacing={1} sx={{ width: '100%', mb: 1 }}>
      <FormControl error={!!titleErrors}>
        <Input {...form.register(`${path}.title`)} placeholder="Title" />
        {titleErrors && <FormHelperText>{titleErrors.message}</FormHelperText>}
      </FormControl>

      <SubPointsForm index={index} path={`${path}.sub_points`} />
      <ExamplesForm path={`${path}.examples`} />
    </Stack>
  );
}

function SubPointsForm({ index, path }) {
  const form = useFormContext();
  const { fields: subPoints, append, remove } = useFieldArray({ name: path });

  function subPoint(field, subIndex) {
    const subPointNumber = index + 1 + '.' + (subIndex + 1);
    const subPointPath = `${path}.${subIndex}.text`;
    const subPointError = form.getFieldState(subPointPath).error;

    return (
      <Box key={field.id} sx={{ display: 'flex', gap: 1 }}>
        <RoundNumber number={subPointNumber} size="sm" sx={{ mt: '4px' }} />
        <FormControl size="sm" sx={{ width: '100%' }} error={!!subPointError}>
          <Input {...form.register(subPointPath)} placeholder="Sub Point" />
          {subPointError && <FormHelperText>{subPointError.message}</FormHelperText>}
        </FormControl>
        {subPoints.length > MIN_SUB_POINTS && <RemoveFormElementButton size="sm" onClick={() => remove(subIndex)} />}
      </Box>
    );
  }

  return <>
    {subPoints.map((field, subIndex) => subPoint(field, subIndex))}
    {subPoints.length < MAX_SUB_POINTS && <Box sx={{ display: 'flex' }}>
      {subPoints.length > 0 && <Box sx={{ width: '34px' }} />}
      <Button startDecorator={<Add />} variant="outlined" size="sm" onClick={() => append({ text: '' })}>Add Sub Point</Button>
    </Box>}
  </>
}

function ExamplesForm({ path }) {
  const form = useFormContext();
  const { fields: examples, append, remove } = useFieldArray({ name: path });

  function example(field, index) {
    const examplePath = `${path}.${index}.text`;
    const exampleError = form.getFieldState(examplePath).error;

    return (
      <Box key={field.id} sx={{ display: 'flex', gap: 1 }}>
        <FormControl size="sm" sx={{ width: '100%' }} error={!!exampleError}>
          <Input {...form.register(examplePath)} placeholder="Example" />
          {exampleError && <FormHelperText>{exampleError.message}</FormHelperText>}
        </FormControl>
        {examples.length > MIN_EXAMPLES && <RemoveFormElementButton size="sm" onClick={() => remove(index)} />}
      </Box>
    );
  }

  return <>
    <Typography level="body-sm" sx={{ pt: 1 }}>Examples</Typography>
    {examples.map((field, index) => example(field, index))}
    {examples.length < MAX_EXAMPLES && <Box><Button startDecorator={<Add />} variant="outlined" size="sm" onClick={() => append({ text: '' })}>Add Example</Button></Box>}
  </>
}
