import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store'
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { callTemplatesRoutes } from './features/callTemplates/routes'
import { callsRoutes } from './features/calls/routes'
import { projectsRoutes } from './features/projects/routes'
import { onboardingRoutes } from './features/onboarding/routes'
import { companiesRoutes } from './features/companies/routes'
import { contactsRoutes } from './features/contacts/routes'
import './index.css';
import {authRoutes} from "@/features/auth/routes.jsx";
import {AuthBase} from "@/features/auth/components/AuthBase.jsx";
import {RequireAuth} from "@/features/auth/components/RequireAuth.jsx";
import {theme} from "@/theme.js";

const router = createBrowserRouter([
  {
    path: "",
    element: <AuthBase />,
    children: [
      authRoutes,
      onboardingRoutes,
      projectsRoutes,
      {
        path: "/p/:projectId",
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <Navigate to="calls" replace={true} />,
          },
          callTemplatesRoutes,
          callsRoutes,
          companiesRoutes,
          contactsRoutes,
        ]
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>    
    <Provider store={store}>
      <CssVarsProvider theme={theme} disableTransitionOnChange>
        <CssBaseline />
        <RouterProvider router={router} />    
      </CssVarsProvider>
    </Provider>
  </React.StrictMode>,
)
