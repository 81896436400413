import React, {useMemo} from "react";
import {AgendaFulfillment} from "@/features/calls/models/AgendaFulfillment.jsx";
import {AgendaPoint} from "@/components/features/Agenda/AgendaPoint.jsx";
import {Box, Typography, useTheme} from "@mui/joy";

export function AgendaNextActionItems({ data, fulfilled }) {
  const theme = useTheme();
  const fulfillment = useMemo(() => new AgendaFulfillment(data, fulfilled), [data, fulfilled]);

  const next = fulfillment.getNextUnfulfilledPoint();
  const missed = fulfillment.getMissedPoints();

  return (
    <Box sx={{ mx: -3, px: 3, pt: 1, borderTop: `1px dashed ${theme.vars.palette.divider}` }}>

      {next >= 0 ? <Box>
        <Typography level="title-md" pb={1}><strong>Next</strong></Typography>
        <AgendaPoint key={next} index={next} point={data.points[next]} fulfillment={fulfillment} />
      </Box> : <Typography level="body" pb={1}>No pending action items</Typography>}
      {missed.length > 0 &&
        <Box sx={{ mx: -3, px: 3, pt: 1, backgroundColor: theme.vars.palette.neutral["100"], borderTop: `1px dashed ${theme.vars.palette.divider}` }}>
          <Typography level="title-md" pb={1}><strong>Skipped</strong></Typography>
          {missed.map(index => <AgendaPoint key={next} index={index} point={data.points[index]} fulfillment={fulfillment} />)}
        </Box>
      }
    </Box>
  );
}

