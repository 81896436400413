import {Box, Typography, useTheme} from "@mui/joy";
import {RoundNumber} from "@/components/RoundNumber.jsx";
import {AgendaSubPoints} from "@/components/features/Agenda/AgendaSubpoints.jsx";

export function AgendaPoint({ point, index, fulfillment }) {
  const theme = useTheme();
  const done = fulfillment.isPointFulfilled(index);

  return (
    <Box sx={{ mx: -3, px: 3, pt: 2, pb: 3,borderTop: `1px dashed ${theme.vars.palette.divider}` }}>
      <Box sx={{ display: 'flex', gap: 1.5 }}>
        <RoundNumber number={index + 1} done={done} />
        <Typography level="h5" sx={ done ? { textDecoration: 'line-through', color: 'var(--joy-palette-neutral-400)' } : {}}>{point.title}</Typography>
      </Box>
      <AgendaSubPoints index={index} data={point.sub_points} fulfillment={fulfillment} />
    </Box>
  );
}