import {FormProvider, useForm} from 'react-hook-form';
import {
  Button,
  Typography,
  Stack,
  Divider,
  Alert
} from '@mui/joy';
import {auth} from '../firebase';
import {useSignInWithEmailAndPassword} from 'react-firebase-hooks/auth';
import {AuthLayout} from "@/components/layouts/AuthLayout.jsx";
import {yupResolver} from "@hookform/resolvers/yup";
import {signInSchema, newSignIn} from "@/features/auth/schema.js";
import {fromAuthErrorCode} from "@/features/auth/errors.js";
import {Navigate, useSearchParams} from "react-router-dom";
import {GoogleButton} from "@/features/auth/components/GoogleButton.jsx";
import {FormInput} from "@/components/forms/FormInput.jsx";
import {Link} from "@/components/Link.jsx";

export function SignInPage() {
  const [searchParams] = useSearchParams();

  const form = useForm({
    defaultValues: newSignIn(),
    resolver: yupResolver(signInSchema),
  });

  const [
    signInWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(auth);

  function onSubmit(data) {
    signInWithEmailAndPassword(data.email, data.password)
  }

  if (user != null) {
    const to = searchParams.get('redirect') ?? '/';
    return <Navigate to={to} replace={true} />;
  }

  return (
    <AuthLayout>
      <Stack gap={4}>
        {error && <Alert color="danger">{fromAuthErrorCode(error.code)}</Alert>}
        <Stack gap={1}>
          <Typography component="h1" level="h2">Sign in</Typography>
          <Typography level="body-sm">
            New to Raise AI?{' '}<Link to="/sign-up" level="title-sm">Sign up!</Link>
          </Typography>
        </Stack>
        <GoogleButton />
        <Divider>or</Divider>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <FormInput name="email" label="Email" size="lg" />
              <FormInput name="password" label="Password" type="password" size="lg" />

              <Link level="title-sm" to="/forgotten-password">
                Forgot your password?
              </Link>
              <Button type="submit" loading={loading} fullWidth sx={{mt: 2.5}} size="lg">Sign in</Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </AuthLayout>
  );
}
