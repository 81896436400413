import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { ContactForm } from '../components/ContactForm';
import { useParams } from "react-router-dom";
import { useGetContactQuery, useUpdateContactMutation } from '../api';

export function UpdateContactPage() {
  const { projectId, id } = useParams();
  const { isFetching, data } = useGetContactQuery({ projectId, id });

  const [saveContact, saveResult] = useUpdateContactMutation();

  async function save(data) {
    await saveContact({ projectId, id, data });
  }

  return (
    <DrawerLayout title="Update Contact">
      {isFetching ? 'Loading...' : <ContactForm data={data.data} save={save} saveResult={saveResult} />}
    </DrawerLayout>
  );
}

