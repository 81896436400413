import {Box, Card, Chip, Divider, Link, Stack, Switch, Typography, useTheme} from '@mui/joy';
import {useParams} from "react-router-dom";
import {Agenda} from '@/components/features/Agenda/Agenda.jsx';
import {Objections} from '@/components/features/Objections';
import {useGetCallWithTemplateQuery} from '../api';
import {FullScreenLoader} from "@/components/FullScreenLoader.jsx";
import {FullScreenCentered} from "@/components/FullScreenCentered.jsx";
import {AgendaNextActionItems} from "@/components/features/Agenda/AgendaNextActionItems.jsx";
import React, {useState} from "react";

export function CallAssistantPage() {
  const { projectId, id } = useParams();

  // TODO: Use socket instead of polling
  const { isLoading, data: response } = useGetCallWithTemplateQuery({ projectId, id }, {
    pollingInterval: 2000,
    skipPollingIfUnfocused: true,
  });

  return (isLoading ? <FullScreenLoader /> : <CallAssistant data={response.data} />);
}

function CallAssistant({ data }) {
  const [fullAgenda, setFullAgenda] = useState(false);

  if (data.status === "pending") {
    return <Message text="Call hasn't started yet" />;
  }

  if (data.status === "finished") {
    return <Message text="Call has already finished" />;
  }

  return (
    <Box px={3} py={2}>
      <Typography level="h4" sx={{ mb: 0 }}>{data.company?.name ?? "Unknown company"}</Typography>
      <Typography level="body-sm">{data.template.name}</Typography>

      {/*<Divider sx={{ mx: -3, my: 2, mt: 3 }} />*/}

      {/*<Typography level="h4" sx={{ mb: 2 }}>Hint</Typography>*/}
      {/*<Stack gap={1} sx={{mb: 3}}>*/}
      {/*  <Card variant="soft" color="neutral">*/}
      {/*    <Typography level="body-xs">&quot;Is it possible to integrate Raise AI with Salesforce?&quot;</Typography>*/}
      {/*  </Card>*/}
      {/*  <Card variant="soft" color="primary">*/}
      {/*    <Typography level="body-sm">No, we support hubspot only, however Salesforce is on the roadmap.</Typography>*/}
      {/*  </Card>*/}
      {/*</Stack>*/}

      <Divider sx={{ mx: -3, my: 2, mt: 2 }} />

      <Box sx={{ mb: 2, display: "flex", justifyContent: 'space-between' }}>
        <Typography level="h4">Agenda</Typography>
        <Link color="neutral" level="body-sm" onClick={() => setFullAgenda(!fullAgenda)}>
          {fullAgenda ? 'Hide fulfilled' : 'Show all'}
        </Link>
      </Box>

      {fullAgenda ?
        <Agenda data={data.template.agenda} fulfilled={data.details.fulfilled_agenda_items} /> :
        <AgendaNextActionItems data={data.template.agenda} fulfilled={data.details.fulfilled_agenda_items} />
      }
      <Divider sx={{ mx: -3, my: 2, mt: 0 }} />

      <Typography level="h4" sx={{ mb: 2 }}>Objections</Typography>
      <Objections data={data.details.objections} />
    </Box>
  );
}

function Message({ text }) {
  return (
    <FullScreenCentered>
      <Typography level="h4">{text}</Typography>
    </FullScreenCentered>
  );
}