import { useParams, Outlet } from "react-router-dom";
import { ProjectLayout } from '@/components/layouts/ProjectLayout.jsx';
import { PageHeader } from '@/components/PageHeader';
import { CrudTable } from '@/components/CrudTable';
import { CallStatus } from '@/components/features/CallStatus';
import { useGetCallsQuery, useDeleteCallMutation } from '../api';
import { useCrudProjectMutation } from '@/hooks/useCrudProjectMutation';
import { created } from '@/utils/columns';
import OpenInBrowserRoundedIcon from '@mui/icons-material/OpenInBrowserRounded';
import {openLiveAssistantWindow} from "@/features/calls/actions.js";

export function CallsPage() {
  const { projectId } = useParams();
  const useQuery = () => useGetCallsQuery({ projectId });
  const useDelete = useCrudProjectMutation(projectId, useDeleteCallMutation);
  
  const options = {
    names: {
      singular: 'call',
      plural: 'calls',
    },    
    
    columns: [
      { label: 'Contact', getter: (row) => row.contact.name },
      { label: 'Company', getter: (row) => row.company.name },
      { label: 'Template', getter: (row) => row.template.name },
      { label: 'Status', getter: (row) => <CallStatus status={row.status} /> },
      created(),      
    ],

    actions: {
      list: useQuery,
      create: 'create',
      get: ':id',
      edit: (row) => row.status === 'pending',
      delete: useDelete,
      extra: [{
        type: 'assistant',
        icon: <OpenInBrowserRoundedIcon />,
        tooltip: "Open live assistant",
        display: (row) => row.status === 'active',
        onClick: (row) => openLiveAssistantWindow(projectId, row.id),
      }],
    },
  };

  return (
    <ProjectLayout>
      <PageHeader title="Calls" />
      <CrudTable {...options} />
      <Outlet />
    </ProjectLayout>
  );
}
