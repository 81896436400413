import { useParams, Outlet } from "react-router-dom";
import { ProjectLayout } from '@/components/layouts/ProjectLayout.jsx';
import { PageHeader } from '@/components/PageHeader';
import { CrudTable } from '@/components/CrudTable';
import { useGetContactsQuery, useDeleteContactMutation } from '../api';
import { useCrudProjectMutation } from '@/hooks/useCrudProjectMutation';
import { created } from '@/utils/columns';

export function ContactsPage() {
  const { projectId } = useParams();
  const useQuery = () => useGetContactsQuery({ projectId });
  const useDelete = useCrudProjectMutation(projectId, useDeleteContactMutation);

  const options = {
    names: {
      singular: 'contact',
      plural: 'contacts',
    },

    columns: [
      { label: 'Name', getter: (row) => row.first_name + ' ' + row.last_name },
      { label: 'E-mail', getter: (row) => row.email },
      created(),
    ],

    actions: {
      list: useQuery,
      create: 'create',
      get: ':id',
      delete: useDelete,
    },
  };

  return (
    <ProjectLayout>
      <PageHeader title="Contacts" />
      <CrudTable {...options} />
      <Outlet />
    </ProjectLayout>
  );
}
