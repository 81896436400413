import { useNavigate } from "react-router-dom";
import {IconButton, Tooltip} from "@mui/joy";

export function ExtraAction({ to, tooltip, onClick, icon }) {
  const navigate = useNavigate();

  function click(e) {
    e.stopPropagation();
    if (onClick) {
      onClick();
    } else {
      navigate(to);
    }
  }

  return (
    <Tooltip title={tooltip} size="sm">
      <IconButton size="sm" variant="plain" onClick={click}>{icon}</IconButton>
    </Tooltip>
  );
}