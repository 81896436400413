import * as yup from 'yup';

export function newContact() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    linkedin: '',
  };
}

export const contactSchema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().required('E-mail is required').email(),
  linkedin: yup.string().optional().url(),
});
