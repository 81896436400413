import {baseApi, extendCrudApiByProject} from '@/utils/api.js';

const callTemplatesApi = extendCrudApiByProject({
  entityNameSingular: 'CallTemplate',
  entityNamePlural: 'CallTemplates',
  entityPath: 'call-templates',
});

export const {
  useGetCallTemplatesQuery,
  useGetCallTemplateQuery,
  useUpdateCallTemplateMutation,
  useDeleteCallTemplateMutation,
  useCreateCallTemplateMutation,
} = callTemplatesApi;

const sharedCallTemplatesApi = baseApi.enhanceEndpoints({
  addTagTypes: ['SharedCallTemplates'],
}).injectEndpoints({
  endpoints: (builder) => ({
    getSharedCallTemplates: builder.query({
      query: () => ({
        url: '/call-templates/',
        method: 'GET',
      }),
      providesTags: ['SharedCallTemplates'],
    }),
    getSharedCallTemplate: builder.query({
      query: ({ id }) => ({
        url: `/call-templates/${id}`,
        method: 'GET',
      }),
      providesTags: ['SharedCallTemplates'],
    }),
  }),
});

export const {
  useGetSharedCallTemplatesQuery,
  useLazyGetSharedCallTemplateQuery,
} = sharedCallTemplatesApi;
