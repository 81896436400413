import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { ContactForm } from '../components/ContactForm';
import { useParams } from "react-router-dom";
import { useCreateContactMutation } from '../api';
import { newContact } from '../schema';

export function CreateContactPage() {
  const { projectId } = useParams();

  const data = newContact();
  const [saveContact, saveResult] = useCreateContactMutation();

  async function save(data) {
    await saveContact({ projectId, data });
  }

  return (
    <DrawerLayout title="Create Contact">
      <ContactForm data={data} save={save} saveResult={saveResult} />
    </DrawerLayout>
  );
}
