export function secondsToTime(seconds) {
  const remainingSeconds = Math.floor(seconds % 60);
  const minutes = Math.floor(seconds / 60);
  const remainingHours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (remainingHours > 0) {
    return `${remainingHours}:${pad(remainingMinutes)}:${pad(remainingSeconds)}`;
  }

  return `${remainingMinutes}:${pad(remainingSeconds)}`;
}

function pad(val) {
  return String(val).padStart(2, '0');
}
