import { Stack } from '@mui/joy';
import {AgendaPoint} from "@/components/features/Agenda/AgendaPoint.jsx";
import {AgendaFulfillment} from "@/features/calls/models/AgendaFulfillment.jsx";
import {useMemo} from "react";

export function Agenda({ data, fulfilled }) {
  const fulfillment = useMemo(() => new AgendaFulfillment(data, fulfilled), [data, fulfilled]);

  return (
    <Stack>
      {data.points.map((point, index) => <AgendaPoint key={index} index={index} point={point} fulfillment={fulfillment} />)}
    </Stack>
  );
}
