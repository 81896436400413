import {Avatar, Box, IconButton, Tooltip, Typography} from '@mui/joy';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {useAuthState} from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import {auth} from "@/features/auth/firebase.js";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function ActiveUser() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  async function logout() {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      // TODO
      console.error(err);
    }
  }

  if (user === null) {
    return null;
  }

  useEffect(() => {
    if (user !== null) {
      window.Intercom('boot', {
        app_id: 'ozxftigo',
        email: user.email,
        user_id: user.uid,
      });
    }
  }, [user])

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Avatar
        variant="outlined"
        size="sm"
        src={user.photoURL}
      />
      <Box sx={{ minWidth: 0, flex: 1 }}>
        <Typography level="title-sm">{ user.displayName || user.email }</Typography>
      </Box>
      <Tooltip title="Logout" variant="soft">
        <IconButton size="sm" variant="plain" color="neutral" onClick={logout}>
          <LogoutRoundedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}