import {Box, Stack, Typography} from "@mui/joy";
import {RoundNumber} from "@/components/RoundNumber.jsx";

export function AgendaSubPoints({ index, data, fulfillment }) {
  function renderSubPoint(subIndex, subPoint, fulfillment) {
    const subPointNumber = index + 1 + '.' + (subIndex + 1);
    const done = fulfillment.isSubPointFulfilled(index, subIndex);
    const sx = { position: 'relative', top: '2px' };
    return (
      <Box key={subIndex} sx={{ ml: 4 }}>
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <RoundNumber size="sm" number={subPointNumber} done={done} />
          <Typography level="body-sm" sx={ done ? { textDecoration: 'line-through', color: 'var(--joy-palette-neutral-400)', ...sx } : sx}>{subPoint.text}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Stack gap={1.5} sx={{ mt: data.length > 0 ? 1 : 0 }}>
      {data.map((subPoint, subIndex) => renderSubPoint(subIndex, subPoint, fulfillment))}
    </Stack>
  );
}
