import {Controller, useFormContext} from "react-hook-form";
import {useParams} from "react-router-dom";
import {Option, Select} from "@mui/joy";

export function FormSelectEntity({name, nullable, placeholder, useQuery, displayFn}) {
  const {control} = useFormContext();

  const {projectId} = useParams();
  const {data, isLoading} = useQuery({projectId});

  const items = data?.data ?? [];

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {ref, value, onChange, onBlur}, fieldState: {error}}) => {
        return (
          isLoading ? 'Loading...' : (
          <Select
            ref={ref}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            onBlur={onBlur}
            error={error == null ? 'false' : 'true'}
            placeholder={placeholder}
          >
            {nullable && <Option value={""}>None</Option>}
            {items.map((item) => (
              <Option key={item.id} value={item.id}>
                {displayFn ? displayFn(item) : item.name}
              </Option>
            ))}
          </Select>
          ));
      }}
    />
  );
}
