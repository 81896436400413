import  { CreateCallPage } from './pages/CreateCallPage'
import  { CallPage } from './pages/CallPage'
import  { CallsPage } from './pages/CallsPage'
import  { UpdateCallPage } from './pages/UpdateCallPage'
import {CallAssistantPage} from "@/features/calls/pages/CallAssistantPage.jsx";

export const callsRoutes = {
  path: 'calls',
  children: [{
    path: '',
    element: <CallsPage />,
    children: [{
      path: 'create',
      element: <CreateCallPage />,
    }, {
      path: ':id',
      element: <CallPage />,
    }, {
      path: ':id/update',
      element: <UpdateCallPage />,
    }]
  }, {
    path: ':id/assistant',
    element: <CallAssistantPage />,
  }],
};
