import {GoogleIcon} from "@/features/auth/components/GoogleIcon.jsx";
import {Button} from "@mui/joy";
import {useSignInWithGoogle} from "react-firebase-hooks/auth";
import {auth} from "@/features/auth/firebase.js";
import {Navigate, useSearchParams} from "react-router-dom";

export function GoogleButton() {
  const [searchParams] = useSearchParams();
  const [signInWithGoogle, user, loading] = useSignInWithGoogle(auth);

  if (user != null) {
    const to = searchParams.get('redirect') ?? '/';
    return <Navigate to={to} replace={true} />;
  }

  return (
    <Button
      variant="outlined"
      color="neutral"
      size="lg"
      fullWidth
      startDecorator={!loading && <GoogleIcon/>}
      loading={loading}
      onClick={() => signInWithGoogle()}
      sx={{
        textTransform: "none",
        fontSize: "14px",
        letterSpacing: "0",
      }}
    >
      Continue with Google
    </Button>
  )
}