import {Box} from "@mui/joy";

export function FullScreenCentered({ children }) {
  return (
    <Box sx={{
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {children}
    </Box>
  );
}