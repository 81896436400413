import {extendCrudApiByProject} from '@/utils/api.js';

const contactsApi = extendCrudApiByProject({
  entityNameSingular: 'Contact',
  entityNamePlural: 'Contacts',
  entityPath: 'contacts',
});

export const {
  useGetContactsQuery,
  useGetContactQuery,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useCreateContactMutation,
} = contactsApi;
