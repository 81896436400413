import { extendTheme } from '@mui/joy/styles';

const palette = {
  "primary": {
    50: "#F1EBFE",
    100: "#E4D8FE",
    200: "#C5ABFC",
    300: "#A984FB",
    400: "#8E5CF9",
    500: "#7033F8",
    600: "#602DD3",
    700: "#5327BA",
    800: "#4822A0",
    900: "#3A1B83",
    950: "#351976"
  },
  "neutral": {
    "50": "#FCFCFE",
    "100": "#F5F5FA", //
    "200": "#E1E1ED",
    "300": "#CDCDE0", //
    "400": "#A0A0B7",
    "500": "#686881", //
    "600": "#474757",
    "700": "#31313B",
    "800": "#26262d",
    "900": "#1a1b1f",
},
  "danger": {
    50: "#FEE6EF",
    100: "#FDCEE0",
    200: "#FBA2C4",
    300: "#FA70A5",
    400: "#F83F86",
    500: "#F61268",
    600: "#E2095C",
    700: "#C90852",
    800: "#B10748",
    900: "#93063C",
    950: "#8A0538"
  },
  "success": {
    50: "#E2FEED",
    100: "#C4FCDB",
    200: "#8FFABA",
    300: "#54F795",
    400: "#19F571",
    500: "#09CB57",
    600: "#08B54D",
    700: "#07A145",
    800: "#068E3C",
    900: "#057532",
    950: "#056B2E"
    // Alternative green
    // 50: "#F4FCE3",
    // 100: "#E9F8C7",
    // 200: "#D2F18F",
    // 300: "#C3EE61",
    // 400: "#A4E804",
    // 500: "#73D200",
    // 600: "#68BC04",
    // 700: "#5DA508",
    // 800: "#477710",
    // 900: "#3E6414",
    // 950: "#3C6014"
  },
  "warning": {
    50: "#FFF4E5",
    100: "#FFE9CC",
    200: "#FFD399",
    300: "#FFBD66",
    400: "#FFA733",
    500: "#FF9000",
    600: "#E68200",
    700: "#CC7400",
    800: "#AD6200",
    900: "#945400",
    950: "#8A4E00"
  },
};

export const theme = extendTheme({
  colorSchemes: {
    light: { palette },
    dark: { palette },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      letterSpacing: "-2px",
    },
    h2: {
      fontFamily: "Montserrat",
    },
    h3: {
      fontFamily: "Montserrat",
    },
    h4: {
      fontFamily: "Montserrat",
    },
    "title-lg": {
      fontFamily: "Montserrat",
      opacity: 0.85,
    },
  },
  fontSize: {
    xl4: "54px",
  },
  fontWeight: {
    xl: 600,
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontFamily: "Montserrat",
          fontWeight: (ownerState.size !== "sm") ? 500 : 600,
          textTransform: "uppercase",
          letterSpacing: "1px",
          // fontSize: '12px',
          // lineHeight: '12px',
          fontSize: (ownerState.size === "lg") ? "12px" : (ownerState.size === "md") ? "11px" : "10px",
          lineHeight: (ownerState.size === "lg") ? "12px" : (ownerState.size === "md") ? "11px" : "10px",
          minHeight: (ownerState.size === "lg") ? "44px" : (ownerState.size === "md") ? "37px" : "32px",
          paddingLeft: (ownerState.size !== "sm") ? "18px" : "12px",
          paddingRight: (ownerState.size !== "sm") ? "18px" : "12px",
        })
      }
    },
    JoyDrawer: {
      styleOverrides: {
        content: {
          backgroundColor: "#fff",
        }
      }
    },
  }
});