import { Box, Stack, Typography } from '@mui/joy';
import { secondsToTime } from '@/utils/datetime';
import { actorToRole } from '@/utils/strings';

export function Transcript({ data }) {
  if (data.length === 0) {
    return <Typography level="body-md">Nothing to transcribe</Typography>;
  }
  return (
    <Stack gap={2}>
      {data.map((line, _) => <TranscriptLine key={line.id} line={line} />)}
    </Stack>
  );
}

function TranscriptLine({ line }) {
  const time = secondsToTime(line.start_time);
  const width = (time.length > 5) ? '48px' : ((time.length > 4) ? '42px' : '36px');
  return (
    <Box>
      <Typography level="body-xs" sx={{ float: 'left', pt: '2px', width: width }}>{time}</Typography>
      <Typography level="body-sm" sx={{ ml: width }}><strong>{line.participant_name} ({actorToRole(line.role)}):</strong> {line.text}</Typography>
    </Box>
  );
}
