import { Stack } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from "react-hook-form";
import { FormButtons } from '@/components/FormButtons';
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams } from "react-router-dom";
import {contactSchema} from "@/features/contacts/schema.js";
import {FormInput} from "@/components/forms/FormInput.jsx";

export function ContactForm({ data, save, saveResult }) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(contactSchema)
  });

  async function onSubmit(data) {
    await save(data);
    navigate(`/p/${projectId}/contacts`);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            pt: 2,
            pb: 3,
          }}
        >
          <Stack spacing={2}>
            <FormInput name="first_name" label="First name"/>
            <FormInput name="last_name" label="Last name"/>
            <FormInput name="email" label="E-mail"/>
          </Stack>
        </Stack>
        <FormButtons isSaving={saveResult.isLoading} />
      </form >
    </FormProvider>
  );
}

