import {useCreateCallTemplateMutation, useGetSharedCallTemplatesQuery, useLazyGetSharedCallTemplateQuery} from "@/features/callTemplates/api.js";
import {Box, Button, List, Sheet, Typography} from "@mui/joy";
import {useParams} from "react-router-dom";
import {omit} from "lodash";

export function SelectSharedPlaybook({ hideHeader, onClone }) {
  const {isLoading, error, data} = useGetSharedCallTemplatesQuery();
  const width = hideHeader ? '250px' : '400px';

  return (
    <Box sx={{ mt: hideHeader ? 0 : 3 }}>
      {!hideHeader && <Typography level="h4" sx={{ mb: 1 }}>Shared playbooks</Typography>}
      <Box>
        {isLoading ? 'Loading...' : error ? 'Error' :
          <List
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(' + width + ', 1fr))',
              gap: hideHeader ? 1 : 2,
            }}
          >{data.data.map((item) => <SharedPlaybook key={item.id} item={item} small={hideHeader} onClone={onClone} />)}
          </List>
        }
      </Box>
    </Box>
  );
}

function SharedPlaybook({ item, small, onClone }) {
  const { projectId } = useParams();

  const [getDetails] = useLazyGetSharedCallTemplateQuery();
  const [createCallTemplate] = useCreateCallTemplateMutation();

  async function clone() {
    const { data } = await getDetails({ id: item.id });
    const callTemplate = omit(data.data, ['id', 'created']);
    const { data: result } = await createCallTemplate({ projectId, data: callTemplate });
    if (onClone) onClone({ id: result.data.id, name: result.data.name });
  }

  return (
    <Sheet size="md" variant="outlined" sx={{ borderRadius: small ? 4 : 'sm', p: 2, listStyle: 'none' }}>
      <Typography level="body-lg" sx={{ mt: -0.5 }}>{item.name}</Typography>
      <Typography level="body-sm" sx={{ mt: 0.75, mb: 2 }}>{item.description}</Typography>
      <Box>
        <Button variant="outlined" onClick={clone}>Copy To Use</Button>
      </Box>
    </Sheet>
  );
}